<template>
    <div class="Luckylist">
        <div class="Luckylist_line1">
            <img src="@/assets/img/lucklist_icon1.png" alt="">
            <span>{{ $t('lang.幸运榜单') }}</span>
        </div>
        <div class="Luckylist_line2">
            <div class="Luckylist_line2_left">
                <img src="@/assets/img/lucklist_icon2.png" alt="">
                <span>{{ $t('lang.当前奖池金额') }}：{{ luckyInfo.reward_amount }} </span>
            </div>
            <img class="Luckylist_line2_right" src="@/assets/img/lucklist_img1.png" alt="">
        </div>
        <div class="Luckylist_line3">
            <div class="Luckylist_line3_box">{{ formattedTimehours }}</div>
            <div class="Luckylist_line3_quote">:</div>
            <div class="Luckylist_line3_box">{{ formattedTimeminutes }}</div>
            <div class="Luckylist_line3_quote">:</div>
            <div class="Luckylist_line3_box">{{ formattedTimeseconds }}</div>
        </div>
        <div class="Luckylist_line4">
            <img src="@/assets/img/lucklist_icon3.png" alt="">
            <span>{{ $t('lang.开奖倒计时') }}</span>
        </div>
        <div class="Luckylist_line5">
            <div class="Luckylist_line5_NoLength" v-if="luckyInfo.list.length == 0">
                <img src="@/assets/img/lucklist_bill_img1.png" alt="">
                <span>{{ $t('lang.暂无数据') }}~</span>
            </div>
            <div class="Luckylist_line5_listbox" v-else>
                <div class="Luckylist_line5_listbox_title">最新质押记录</div>
                <div class="Luckylist_line5_listbox_li" v-for="(item, index) in luckyInfo.list" :key="index">
                    <div>{{ item.address }}</div>
                    <div>{{ item.amount }}</div>
                    <div>{{ item.created_at }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { MintPool_api } from '@/api/index';
import { mapState } from 'vuex';
export default {
    data() {
        return {
            timer: null,
            luckyInfo: {
                list: [],
                countdown: 0,
                reward_amount: 0
            }
        }
    },
    computed: {
        ...mapState(['account', 'lang']),
        formattedTimehours() {

            return String(Math.floor(this.luckyInfo.countdown / 3600)).padStart(2, '0');;
        },

        formattedTimeminutes() {
            return String(Math.floor((this.luckyInfo.countdown % 3600) / 60)).padStart(2, '0');
        },
        formattedTimeseconds() {
            return String(this.luckyInfo.countdown % 60).padStart(2, '0');
        }
    },
    watch: {
        account() {

            this.init()
        },
        lang() {
            this.init()
        }
    },
    created() {
        this.init()
    },
    beforeDestroy() {
        if (this.timer) {
            clearInterval(this.timer); // 组件销毁时清除定时器
        }
    },
    methods: {
        init() {
            this.lpuserluckylist()
        },
        startCountdown() {
            this.timer = setInterval(() => {
                if (this.luckyInfo.countdown > 0) {
                    this.luckyInfo.countdown -= 1;
                } else {
                    this.lpuserluckylist()
                    clearInterval(this.timer); // 倒计时结束清除定时器
                }
            }, 1000);
        },
        lpuserluckylist() {
            MintPool_api.lpuserluckylist(this.account).then(res => {
                if (res.code == 200) {
                    this.luckyInfo = res.data
                    if(this.luckyInfo.countdown <= 0) return;
                    this.startCountdown();
                }
            })
        },

    },
}

</script>
<style scoped lang='less'>
.Luckylist {
    margin-top: 18px;
    background: #F7F7F7;
    border-radius: 10px;
    padding: 12px 13px;

    .Luckylist_line5 {
        .Luckylist_line5_listbox {
            margin-top: 10px;

            .Luckylist_line5_listbox_title {
                font-size: 14px;
                color: #000000;
                font-weight: bold;
            }

            .Luckylist_line5_listbox_li {
                margin-top: 12px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                color: #666666;
                font-weight: bold;
            }
        }

        .Luckylist_line5_NoLength {
            padding: 30px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #999999;
            font-size: 12px;

            img {
                margin-bottom: 13px;
                width: 47.01px;
                height: 55px;
            }
        }
    }

    .Luckylist_line4 {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #66B4AE;
        font-size: 14px;
        gap: 5px;

        img {
            width: 16px;
            height: 16px;
        }
    }

    .Luckylist_line3 {
        display: flex;
        align-items: center;
        justify-content: center;

        .Luckylist_line3_quote {
            color: #000;
            font-size: 16px;
            font-weight: bold;
            margin: 0 11px;
        }

        .Luckylist_line3_box {
            width: 35px;
            height: 40px;
            opacity: 1;
            border-radius: 6px;
            background: #66B4AE;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            color: #FFFFFF;
            font-weight: bold;
        }
    }

    .Luckylist_line2 {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .Luckylist_line2_left {
            margin-top: 20px;
            margin-left: 20px;
            display: flex;
            align-items: center;
            color: #000000;
            font-size: 14px;
            font-weight: bold;

            img {
                width: 20px;
                height: 20px;
                margin-right: 8px;
            }
        }

        .Luckylist_line2_right {
            width: 122px;
            height: 122px;
        }
    }

    .Luckylist_line1 {
        display: flex;
        align-items: center;
        color: #000000;
        font-size: 14px;
        font-weight: bold;
        gap: 7px;

        img {
            width: 24px;
            height: 24px;
        }
    }

}
</style>